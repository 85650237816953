import React from 'react'
import {Banner} from 'components/banners'
import Styles from './header.module.css'

export const Header = (props) => {
  return (
    <>
      {props.featured_media && props.featured_media.localFile && props.featured_media.localFile.childImageSharp && props.featured_media.localFile.childImageSharp.desktop &&

       <Banner
         image={props.featured_media.localFile.childImageSharp.desktop}
       />
      }
      <div className={Styles.container}>
        <h1 className={Styles.title} dangerouslySetInnerHTML={{__html:props.title}}/>
        <p className={Styles.date}>{props.date}</p>
        <div className="flex flex-wrap">
          {props.tags && props.tags.length > 0 && props.tags.map((tag, index)=>{
            const show_sep = index < props.tags.length-1;
            return (
              <div className={Styles.tag}>
                <span dangerouslySetInnerHTML={{__html:tag.name}}/>
                {show_sep &&<p className={Styles.sep}>-</p>}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
