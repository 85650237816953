import React from "react"
import {PostSection} from 'components/sections'
import RelatedPosts from "../relatedPosts"
import {BgPattern} from 'components/backgrounds'
import BgStyle from 'components/backgrounds/patterns.module.css'
import SingleStyle from './footer.module.css'
export const Footer =  (props) => (
  <>
    {
      props.related_news_section
        && props.related_news_section.items
        && props.related_news_section.items.length > 0
        &&
    <PostSection
      {...props.related_news_section.options}
    >
      <BgPattern className={`${BgStyle.pattern1} ${SingleStyle.bgRelatedPosts}`}/>
      <RelatedPosts {...props.related_news_section}/>


     </PostSection>
    }
  </>
)
