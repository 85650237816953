import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout'

import {NewsContent, Header} from 'components/news'


const SingleNewsTemplate = (props) => {
  const data = props.data;
  const {content, date, title, featured_media, tags, slug, classes, ancestors, related_news_section} = data.wordpressWpNews
  return (
    <Layout
      articleId={"news-"+slug}
      articleClassName={classes}
      context = {props.pageContext}
      location = {props.location}
      ancestors={ancestors}
      >
        <NewsContent {...props.data.wordpressWpNews}/>

    </Layout>
  )
};


export default SingleNewsTemplate

export const newsQuery = graphql `
query newById($id: String!){
  wordpressWpNews(id:{eq:$id}){
    title
    content
    path
    slug
    type
    classes
    date(formatString: "LL")
    ancestors {
      link
      label
    }
    wordpress_id
    tags {
      name
    }
    related_news_section{
        options{
          title
          read_more_button_label
        }
        items{
          wordpress_id
          path
          date(formatString: "LL")
          title
          tags {
            name
          }
          featured_media  {
            localFile {
              childImageSharp {
                fluid ( maxWidth:370){
                  ...GatsbyImageSharpFluid

               }
              }
            }
          }
        }
      }
    featured_media {
      localFile {
        ...PageBannerImages
      }
    }
  }
}
`
